import React from 'react'
import dynamic from 'next/dynamic'

// This approach seems to be an issue 
// https://github.com/vercel/next.js/issues/49454
// Basically everything is included in the bundle.
const Accordions = dynamic(() => import('./accordions'), )
const ArtistsWall = dynamic(() => import('./artists-wall'))
const Divider = dynamic(() => import('./divider'))
const Spotlight = dynamic(() => import('./spotlight'))
const TextContent = dynamic(() => import('./text-content'))
const ProductSlider = dynamic(() => import('./product-slider'))
const StorySlider = dynamic(() => import('./story-slider'))
const ArticlesList = dynamic(() => import('./articles-list'))
const RelatedArticle = dynamic(() => import('./related-article'))
const Gallery = dynamic(() => import('./gallery'))
const Video = dynamic(() => import('./video'))
const Quote = dynamic(() => import('./quote'))
const Split = dynamic(() => import('./split'))
const HTMLRenderer = dynamic(() => import('./html-renderer'))
export const Module = ({ module, imagePriority }) => {
  const type = module._type

  switch (type) {
    case 'spotlight':
      return <Spotlight data={module} imagePriority={imagePriority} />
    case 'artistsWall':
      return <ArtistsWall data={module} />
    case 'productSlider':
      return <ProductSlider data={module} />
    case 'storySlider':
      return <StorySlider data={module} />
    case 'divider':
      return <Divider data={module} />
    case 'accordions':
      return <Accordions data={module} />
    case 'textContent':
      return <TextContent data={module} />
    case 'articlesList':
      return <ArticlesList data={module} />
    case 'relatedArticle':
      return <RelatedArticle data={module} />
    case 'gallery':
      return <Gallery data={module} />
    case 'video':
      return <Video data={module} />
    case 'quote':
      return <Quote data={module} />
    case 'split':
      return <Split data={module} />
    case 'htmlRenderer':
      return <HTMLRenderer data={module} />
    default:
      return null
  }
}
