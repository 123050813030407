import React from 'react'

import CustomLink from '@components/link'
import { ProductPrice, ProductWishlistToggle } from '@components/product'

function ProductItem({ product, photo, onClose, type }) {
  return (
    <div className="overlay-item">
      <div className="flex w-full">
        <div className="overlay-item--photo photo-dropshadow is-sm">
          {photo && (
            <CustomLink
              link={{
                page: product,
              }}
            >
              <img loading='lazy' src={photo} />
            </CustomLink>
          )}
        </div>
        <div className="flex flex-grow ml-4 items-center">
          <div className="flex flex-grow items-center">
            <p className="overlay-item--title">
              <CustomLink
                link={{ page: product }}
                onClick={onClose}
                className="overlay-item--link"
              >
                {product.title}
              </CustomLink>
              <br />
              {product.artist && (
                <>
                  By{' '}
                  <CustomLink
                    link={{
                      page: product.artist,
                    }}
                    shallow={type !== 'artist'}
                    onClick={onClose}
                    className="underline"
                  >
                    {product.artist.title}
                    <br />
                  </CustomLink>
                </>
              )}
              <ProductPrice prefix="From " item={product} />
            </p>
          </div>
          {type === 'wishlist' && <ProductWishlistToggle product={product} />}
        </div>
      </div>
    </div>
  )
}

export default ProductItem
