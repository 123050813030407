import React, { useEffect, useState } from 'react'

import { useSiteContext, useTogglePageOverlay } from '@lib/context'

import { getArtist, getPage } from '@data'
import { isBrowser } from '@lib/helpers'
import Overlay from '@components/overlays/overlay'
import Artist from '@components/overlays/artist'
import { Module } from '@components/modules'
import ContactForm from '@components/modules/contact-form'

const Page = ({ data: { pageOverlays = [] } }) => {
  const { isPageOverlayOpen } = useSiteContext()
  const [hasFocus, setHasFocus] = useState(false)
  const [page, setPage] = useState()
  const [backPath, setBackPath] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const togglePageOverlay = useTogglePageOverlay()

  const { type, title } = page || {}
  const isArtist = type === 'artist'

  const onPathChange = async (e) => {
    const paths = location.pathname.substring(1).split('/')
    setIsLoading(true)

    // Set back path for closing story
    if (e.detail?.back) setBackPath(e.detail.back)

    let data
    if (paths[0] === 'artist' && paths.length > 1) {
      togglePageOverlay(true)
      data = await getArtist(paths[paths.length - 1], null, 4)
    } else {
      if ((pageOverlays || []).some(({ slug }) => slug === paths[0])) {
        togglePageOverlay(true)
        data = await getPage(paths[0])
      } else {
        onClose(true)
      }
    }

    if (data) {
      setPage(data.page)
      setIsLoading(false)
    }
  }

  const onClose = (isPopstate = false) => {
    if (backPath && !isPopstate) window.history.pushState(null, null, backPath)

    // Clear back path for future
    setBackPath(null)

    // Close
    togglePageOverlay(false)
  }

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('pushstate', onPathChange)
      window.addEventListener('popstate', onPathChange)
    }
  }, [])

  return (
    <Overlay
      open={isPageOverlayOpen}
      focus={hasFocus}
      loading={isLoading}
      onClose={() => onClose()}
      onAnimation={(v) => setHasFocus(v === 'show')}
      className={`is-${type}`}
      label={page?.country}
      {...(isArtist ? { pill: type } : { title })}
    >
      <div className="overlay--content">
        {isLoading ? (
          <p className="overlay--empty">Loading...</p>
        ) : (
          <>
            {type === 'artist' && (
              <Artist page={page} onClose={() => togglePageOverlay(false)} />
            )}
            {type === 'page' && (
              <>
                {page?.slug === 'contact' ? (
                  <ContactForm />
                ) : (
                  page.modules?.map((module, key) => (
                    <Module key={key} module={module} />
                  ))
                )}
              </>
            )}
          </>
        )}
      </div>
    </Overlay>
  )
}

export default Page
