import React from 'react'
import cx from 'classnames'

import {
  useSiteContext,
  useAddWishlistItem,
  useRemoveWishlistItem,
} from '@lib/context'
import Icon from '@components/icon'

const ProductWishlistToggle = ({ product }) => {
  const id = product?.id
  if (!id) return null
  const { wishlist } = useSiteContext()
  const add = useAddWishlistItem()
  const remove = useRemoveWishlistItem()

  const toggle = () => {
    wishlist.indexOf(id) > -1 ? remove(id) : add(id)
  }

  const isSelected = () => wishlist.indexOf(id) > -1

  return (
    <button className="wishlist-toggle" onClick={toggle}>
      <Icon name={isSelected() ? 'HeartFilled' : 'Heart'} color="white" />
    </button>
  )
}

export default ProductWishlistToggle
