import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { m, AnimatePresence } from 'framer-motion'
import cx from 'classnames'

import { fadeAnim } from '@lib/animate'

import Icon from '@components/icon'

const ContactForm = () => {
  const id = 'contact'
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { handleSubmit, register, reset } = useForm()

  // Call to reset the form
  const resetForm = (e) => {
    e.preventDefault()
    reset()
    setError(false)
    setSuccess(false)
    setSubmitting(false)
  }

  // handle form submission
  const onSubmit = (data, e) => {
    e.preventDefault()

    setSubmitting(true)
    setError(false)

    fetch('/api/sendgrid/send-message', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setSubmitting(false)
        if (res?.errors) {
          setError(res?.errors.map((e) => e.message).join(' '))
        } else {
          setSuccess(true)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(true)
        console.log(error)
      })
  }

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence exitBeforeEnter>
        {!error && !success && (
          <m.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnim}
            className="form--fields"
          >
            <input
              type="text"
              name="fullname"
              autoComplete="off"
              className="control--pot"
              aria-hidden="true"
              {...register('fullname')}
            />
            <div className="control--group is-clean">
              <div className="control">
                <label htmlFor={`name-${id}`} className="control--label">
                  Name
                </label>
                <input
                  id={`name-${id}`}
                  name="name"
                  type="text"
                  inputMode="text"
                  autoComplete="name"
                  onFocus={(e) => {
                    e.target.parentNode.classList.add('is-filled')
                  }}
                  onBlur={(e) =>
                    e.target.parentNode.classList.toggle(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  onChange={(e) =>
                    e.target.parentNode.classList.add(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  {...register('name')}
                />
              </div>
              <div className="control">
                <label htmlFor={`email-${id}`} className="control--label">
                  E-mail
                </label>
                <input
                  id={`email-${id}`}
                  name="email"
                  type="email"
                  inputMode="email"
                  autoComplete="email"
                  onFocus={(e) => {
                    e.target.parentNode.classList.add('is-filled')
                  }}
                  onBlur={(e) =>
                    e.target.parentNode.classList.toggle(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  onChange={(e) =>
                    e.target.parentNode.classList.add(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  {...register('email')}
                />
              </div>
              <div className="control">
                <label htmlFor={`subject-${id}`} className="control--label">
                  Subject
                </label>
                <input
                  id={`subject-${id}`}
                  name="subject"
                  type="text"
                  inputMode="text"
                  onFocus={(e) => {
                    e.target.parentNode.classList.add('is-filled')
                  }}
                  onBlur={(e) =>
                    e.target.parentNode.classList.toggle(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  onChange={(e) =>
                    e.target.parentNode.classList.add(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  {...register('subject')}
                />
              </div>
              <div className="control">
                <label
                  htmlFor={`message-${id}`}
                  className="control--label for-textarea"
                >
                  Message
                </label>
                <textarea
                  id={`message-${id}`}
                  name="message"
                  inputMode="text"
                  onFocus={(e) => {
                    e.target.parentNode.classList.add('is-filled')
                  }}
                  onBlur={(e) =>
                    e.target.parentNode.classList.toggle(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  onChange={(e) =>
                    e.target.parentNode.classList.add(
                      'is-filled',
                      Boolean(e.target.value)
                    )
                  }
                  {...register('message')}
                ></textarea>
                <button
                  type="submit"
                  className={cx('btn', {
                    'is-loading': submitting,
                  })}
                  disabled={submitting}
                >
                  <Icon name="ArrowRight" color="white" />
                </button>
              </div>
            </div>
          </m.div>
        )}

        {success && (
          <m.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnim}
          >
            <p>Your message was sent</p>
          </m.div>
        )}

        {error && (
          <m.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnim}
          >
            <p>Something went wrong: {error}</p>
            <button className="btn" onClick={(e) => resetForm(e)}>
              try again
            </button>
          </m.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default ContactForm
