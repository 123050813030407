import { getSanityClient } from '@lib/sanity'
import * as queries from './queries'
import axios from 'axios'

// Fetch all defined page slugs
export async function getAllDynamicSlugs() {
  const data = await getSanityClient().fetch(
    `*[(_type == "page" || _type == "collection" || _type == "category" || _type == "product") && !(_id in [${queries.homeID}, ${queries.errorID}]) && wasDeleted != true && isDraft != true]{ "type": _type, "slug": slug.current, "isCurated": curated, "category": {"slug": category->slug.current} }`
  )
  return data
}

// Fetch all dynamic docs
export async function getAllDocSlugs(doc) {
  const data = await getSanityClient().fetch(
    `*[_type == "${doc}" && !(_id in [${queries.homeID}, ${queries.errorID}]) && wasDeleted != true && isDraft != true]{ "slug": slug.current, "style": {"slug": style->slug.current}, "category": {"slug": category->slug.current}, "isCurated": curated }`
  )
  return data
}

// Fetch all our page redirects
export async function getAllRedirects() {
  const data = await getSanityClient().fetch(
    `*[_type == "redirect"]{ from, to }`
  )
  return data
}

// Fetch a static page with our global data
export async function getStaticPage(pageData, preview) {
  const query = `
  {
    "page": ${pageData},
    ${queries.site}
  }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific dynamic page with our global data
export async function getPage(slug, preview) {
  const slugs = [`/${slug}`, slug, `/${slug}/`]

  const query = `
    {
      "page": *[_type == "page" && slug.current in ${JSON.stringify(
        slugs
      )}] | order(_updatedAt desc)[0]{
        ${queries.page},
        showIntro,
        modules[]{
          ${queries.modules(preview)}
        },
        title,
        seo
      },
      ${queries.site}
    }
    `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific article with our global data
export async function getArticle(slug, preview) {
  const query = `
    {
      "page": *[_type == "article" && slug.current == "${slug}"] | order(publishDate desc)[0]{
        modules[]{
          ${queries.modules(preview)}
        },
        ${queries.article},
        products{
          ...,
          link {
            ${queries.link}
          },
          products[]->${queries.product},
        },
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific dynamic page with our global data
export async function getStoryPage(slug, preview) {
  const query = `
    {
      "page": *[_type == "page" && _id == ${
        queries.exploreID
      }] | order(_updatedAt desc)[0]{
        "type": "story",
        "parentPage": *[_id == ${queries.exploreID}][0]{
          title,
          ${queries.page}
        },
        showIntro,
        modules[]{
          ${queries.modules(preview)}
        },
        "story": *[_type == "story" && slug.current == "${slug}"][0]{
          ${queries.page},
          slides[]{
            products[]->${queries.product},
          }
        },
        title,
        seo
      },
      ${queries.site}
    }
    `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific product with our global data
export async function getProduct(slug, preview) {
  const productId = await getSanityClient(preview).fetch(
    `*[_type == "product" && slug.current == "${slug}"][0].productID`
  )

  const { data: { data: { productRecommendations } = {} } = {} } = await axios({
    url: `https://${process.env.SHOPIFY_STORE_ID}.myshopify.com/api/2022-10/graphql.json`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_API_TOKEN,
    },
    data: {
      query: `
    query productRecommendations ($productId: ID!) {
      productRecommendations (productId: $productId) {
       handle
     }
   }
      `,
      variables: {
        productId: `gid://shopify/Product/${productId}`,
      },
    },
  })
  const query = `
    {
      "page": *[_type == "product" && slug.current == "${slug}" && wasDeleted != true && isDraft != true] | order(_updatedAt desc)[0]{
        "disclaimers": *[_type == "productSettings"][0],
        "product": ${queries.product},
        "productRecommendations": *[_type == "product" && slug.current in ["${(
          productRecommendations || []
        )
          .map((p) => p.handle)
          .join('","')}"]][0...4]${queries.product},
        "productsByArtist": *[_type == "product" && artist->slug.current == ^.artist->slug.current && slug.current != ^.slug.current && wasDeleted != true && isDraft != true]${queries.product} | order(_updatedAt desc) | order(title asc)[0...4],
        relatedProducts[]->${queries.product},
        "frames": *[_type == "product" && category->slug.current == "frames" && defined(frameType) && wasDeleted != true && isDraft != true${
          preview?.active ? ' && _id in path("drafts.**")' : ''
        }]${queries.product},
        "frameTypes": *[_type == "frameType"${
          preview?.active ? ' && _id in path("drafts.**")' : ''
        }]{
          title,
          "slug": slug.current
        },
        title,
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific category with our global data
export async function getCategory(slug, preview) {
  const query = `
    {
      "page": *[_type == "category" && slug.current == "${slug}"] | order(_updatedAt desc)[0]{
        title,
        ${queries.products(preview)},
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific collection with our global data
export async function getCollection(slug, preview) {
  const query = `
    {
      "page": *[_type == "collection" && slug.current == "${slug}"] | order(_updatedAt desc)[0]{
        title,
        "isCurated": curated,
        ${queries.products(preview)},
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific category with our global data
export async function getStory(slug, preview) {
  const query = `
    {
      "page": *[_type == "story" && slug.current == "${slug}"] | order(_updatedAt desc)[0]{
        "story": ${queries.story},
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific category with our global data
export async function getStoryCategory(slug, preview) {
  const query = `
    {
      "page": *[_type == "storyCategory" && slug.current == "${slug}"] | order(_updatedAt desc)[0]{
        "type": _type,
        "category": {title},
        "parentPage": *[_id == ${queries.exploreID}][0]{
          title,
          ${queries.page}
        },
        "stories": *[_type == "story" && category->slug.current == ^.slug.current]${queries.story},
        title,
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

// Fetch a specific collection with our global data
export async function getArtist(slug, preview, limit) {
  const query = `
    {
      "page": *[_type == "artist" && slug.current == "${slug}"] | order(_updatedAt desc)[0]{
        "slug": slug.current,
        title,
        shortText,
        country,
        photo {
          ${queries.imageMeta}
        },
        "products": *[_type == "product" && artist->slug.current == "${slug}" && wasDeleted != true && isDraft != true${
    preview?.active ? ' && _id in path("drafts.**")' : ''
  }]${queries.product} | order(title asc)${limit ? `[0...${limit}]` : ''},
        ${queries.productSettings(preview)},
        seo
      },
      ${queries.site}
    }
  `

  const data = await getSanityClient(preview).fetch(query)

  return data
}

export { queries }
