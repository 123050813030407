import React, { useEffect } from 'react'
import FocusTrap from 'focus-trap-react'
import { AnimatePresence, m } from 'framer-motion'
import cx from 'classnames'

import Icon from '@components/icon'
import { isBrowser } from '@lib/helpers'

const Overlay = ({
  title,
  open,
  focus,
  loading = false,
  pill,
  label,
  update,
  onAnimation,
  onClose,
  children,
  className,
}) => {
  // Overlays are not always part of the DOM anymore, so need to get scrollbar back
  const handleOnClose = () => {
    document.documentElement.classList.remove('prevent-scroll', true)
    onClose()
  }

  const handleKeyDown = (e) => {
    if (e.which === 27) handleOnClose()
  }

  useEffect(() => {
    if (isBrowser)
      document.documentElement.classList.toggle('prevent-scroll', open)
  }, [open])
  return (
    <>
      <FocusTrap
        active={open && focus}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <AnimatePresence>
          {open && (
            <m.div
              key="overlay"
              initial="hide"
              animate="show"
              variants={{
                show: {
                  x: '0%',
                },
                hide: {
                  x: '100%',
                },
              }}
              transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
              onKeyDown={(e) => handleKeyDown(e)}
              onAnimationComplete={onAnimation}
              className={cx('overlay', className, {
                'is-active': open,
                'is-updating': update,
              })}
            >
              <div className="overlay--inner">
                <div className="overlay--header">
                  {pill && !loading && (
                    <div className="pill is-sm mb-0">{pill}</div>
                  )}
                  {title && !loading && (
                    <div className="overlay--title">
                      {title}{' '}
                      {label !== undefined && (
                        <sup className="overlay--count">({label})</sup>
                      )}
                    </div>
                  )}
                  <button className="btn is-close" onClick={handleOnClose}>
                    <Icon name="Cross" className="w-5" color="black" />
                  </button>
                </div>
                {children}
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </FocusTrap>

      <div
        className={cx('backdrop', {
          'is-active': open,
        })}
        onClick={handleOnClose}
      />
    </>
  )
}

export default Overlay
