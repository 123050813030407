import React from 'react'

import CustomLink from '@components/link'
import ProductItem from '@components/overlays/product-item'
import Photo from '@components/photo'

const Artist = ({ page, onClose }) => {
  if (!page) return null
  return (
    <div className="overlay--artist">
      <div className="overlay--title">
        {page.title} {<sup className="overlay--count">({page.country})</sup>}
      </div>
      <Photo photo={page.photo} layout="artist-overlay" />
      <p>{page.shortText}</p>
      {page.products.map((item, i) => (
        <ProductItem
          type={page.type}
          product={item}
          photo={item.photo}
          key={i}
          onClose={onClose}
        />
      ))}
      <hr className="opacity-20 my-0" />
      <div className="text-center my-7">
        <CustomLink
          link={{ page }}
          shallow={false}
          onClick={onClose}
          className="underline"
        >
          See all
        </CustomLink>
      </div>
    </div>
  )
}

export default Artist
